@import "./../../assets/sass/functions";

.popover {
    position: absolute;
    width: 50rem;
    height: 80vh;
    overflow: auto;
    word-wrap: break-word;
    background-color: $color-gradient-6;
    color: black;
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the popover appears above other elements */
    svg {
        height: 2.6rem;
        width: 3.2rem;
        fill: black;
        margin: 0;
    }
    button {
        color: black;
        background-color: $color-gradient-6 !important;
        height: 3.5rem;
        border-radius: 4px;
        width: 4.2rem;
        padding: 0;
        margin: 0;
    }
    &__title {
        font-size: large;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
  }

.helpButton,
.helpButton:link,
.helpButton:active,
.helpButton:visited {
    font-weight: bold;
    color: white;
    background-color: black !important;
    height: 100%;
    border-radius: 4px;
    width: 100%;
    padding: 0;
}


.hoverover {
    svg {
        height: 2.5rem;
        width: 2.5rem;
        fill: black;
        margin: 0;
    }
    &__message {
        position: fixed;
        max-width: 50rem;
        background-color: $color-gradient-6;
        color: black;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        overflow: auto;
        word-wrap: break-word;
    }
}

.largeMarg {
    margin-top: -0.4rem;
}
.smallMarg {
    margin-top: -0.1rem;
}
@import "common/assets/sass/functions";

.container {
    background-color: white;
    padding: 5px;
    width: 50%;
    height: 50%;
    border: solid 2px #eaeaea;

    button {
        height: unset;
        padding: 5px;
    }
    a {
        color: $color-gradient-2;
    }
}

@import "./common/assets/sass/functions";

.home {
    display: flex;
    flex-wrap: wrap;
}

.content {
    display: flex;
    height: calc(100vh - #{$header-height} - #{$footer-height} - #{$header-margin-bottom});
    width: 100%;

    label {
        line-height: 0;
    }
}

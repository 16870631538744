@import "common/assets/sass/functions";

.priceList {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    button {
        color: black;
        background-color: $color-gradient-6 !important;
        height: 3.5rem;
        border-radius: 4px;
        width: 4.2rem;
        padding: 0;
        margin: 0;
    }
    svg {
        height: 2.6rem;
        width: 3.2rem;
        fill: black;
        margin: 0;
    }
}
.priceListHeader {
    border-radius: 5px;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
}
.priceListItem {
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 2px solid #ccc;
    flex: 1;
    min-width: 200px;
    display: flex;
    padding-left: 0.5rem;
}

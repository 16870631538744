@import "common/assets/sass/functions";

.notFound {
    background-color: white;
    width: 100%;
    //border: 1px solid black;
    padding: 20px;
    font-size: large;
    display: flex;
    justify-content: center;
}


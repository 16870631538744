button,
.button:link,
.button:active,
.button:visited {
    border: none;
    color: white;
    background-color: $color-orange-2;
    height: 100%;
    border-radius: 4px;
    width: 100%;
    padding: 0 1.1rem;



    &:disabled{
        background-color: $color-orange-3;
        cursor: not-allowed;
    }
    &.secondary {
        background-color: $color-grey-1;
    }
    &.warning {
        border: 2px solid $color-orange-2;
        color: $color-orange-2;
        background-color: white;
    }
}

.button:link,
.button:active,
.button:visited {
    padding: .95rem 1.1rem;
    text-decoration: none;
}

.button-disabled:link,
.button-disabled:active,
.button-disabled:visited {
    background-color: $color-orange-3;
    cursor: not-allowed;
}


@import "./../../assets/sass/functions";

.flash{
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__img{
        margin-bottom: 1.4rem;
    }
    &__text{
        font-weight: bold;
        color: $color-grey-1;
    }
}

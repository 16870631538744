.radio-item {
    display: block;
    position: relative;
    //padding: 0 6px;
    //margin: 10px 0 0;
  }
  
  .radio-item input[type='radio'] {
    display: none;
  }
  .radio-item input[type='radio']:disabled ~ label {
    color: $color-grey-2;
  }
  
   .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 11px;
    border: .2rem solid $color-grey-2;
    background-color: transparent;
  }
  
  .radio-item input[type=radio]:checked + label:after {
    border-radius: 50%;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 5px;
    left: 0px;
    content: " ";
    display: block;
    border: .45rem solid $color-orange-2;
    background-color: transparent;
  }
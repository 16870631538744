@import "common/assets/sass/functions";

.card {
    //border-top: 1px solid black;
    border-bottom: none;

    &__title {
        margin-right: auto;
        font-weight: normal;

        span {
            svg {
                width: 1.6rem;
                height: 1.6rem;
                margin-left: 0.7rem;
                position: relative;
            }
        }
    }
    &__cta {
        height: 3.8rem;
        margin-left: 1.6rem;

        span {
            top: -0.25rem;
            right: 1.1rem;
            position: relative;

            svg {
                width: 1.6rem;
                height: 1.6rem;
                fill: $color-white;
            }
        }

        a {
            padding-left: 2.2rem !important;
            bottom: -0.55rem;
            position: relative;
        }

        button {
            padding-left: 2.2rem;
        }
    }
}

@import "../../assets/sass/functions";


.footer{
    width: 100%;
    height: $footer-height;
    background-color: $color-black;

    &__text{
        font-size: 1.2rem;
        color: $color-white;
        margin:0.8rem 1.6rem;
        line-height: 1;
    }
}

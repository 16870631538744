@import "./../../assets/sass/functions";

.container{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
    height: 3.8rem;
    position: relative;

    @include form-border;
    @include form-focus;

    span {
        position: absolute;
        right:.6rem;
        bottom:.4rem;
        color: $color-grey-2;
        margin-left: auto;
        font-style: italic;
    }

    &--red{
        border-color: red !important;
        color: red !important;
    }
}
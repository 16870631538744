@import "./../../assets/sass/functions";

.component {
    display: flex;
    margin-top: 1.8rem;
    justify-content: space-between;
    &:first-child{
        margin-top:1.1rem;
    }
}

.component100 {
    flex: 0 0 100%;
}
.component50 {
    flex: 0 0 calc((100% - 0.6rem) / 2);
    position: relative;
    // undo span settings if its inside a button.  DatePicker arrows are impacted.
    button {
        span {
            position: relative;
            right: unset;
            bottom: unset;
        }
    }
    span {
        position: absolute;
        right: 0.6rem;
        bottom: 0.8rem;

        svg {
            width: 1.6rem;
            height: 1.6rem;
            fill: $color-black;
        }
    }
}
.labels {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}

.labelContainer3Cols {
    &__name {
        display: flex;
        justify-content: space-between;
    }
    &__left {
        flex: 1;
        text-align: left;
        word-wrap: break-word;
        width: 1rem;
    }
    &__middle {
        flex: 1;
        text-align: left;
        word-wrap: break-word;
        width: 1rem;
    }
    &__right {
        flex: 1;
        text-align: left;
        word-wrap: break-word;
        width: 1rem;
    }
    &__description {
        color: $color-grey-1;
    }
}
.labelContainer3ColsHeader {
    &__name {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-right: 1.8rem;
        padding-left: 1rem;
        align-items: center;
    }
    &__left {
        flex: 1;
        text-align: left;
        font-weight: bold;
    }
    &__middle {
        flex: 1;
        text-align: left;
        font-weight: bold;
    }
    &__right {
        flex: 1;
        text-align: left;
        font-weight: bold;
    }
}

.labelContainer {
    &__name {
        display: flex;
        justify-content: space-between;
    }
    &__left {
    }
    &__right {
    }
}

.labelContainerHeader {
    &__name {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-left: 1rem;
        padding-right: 1.8rem;
    }
    &__left {
        font-weight: bold;
    }
    &__right {
        font-weight: bold;
    }
}

.datepicker {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
    position: relative;
    height: 3.8rem;

    @include form-border;
    @include form-focus;
}

.lableAndHelp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
        margin-bottom: 0;
    }
}

.custom_node_switch {
    display: flex;
    justify-content: space-between;
    label {
        line-height: unset !important;
    }
}
.custom_node_switch_form_check {
    display: block;
    min-height: 1.5rem;
    input {
        float: left;
    }
}
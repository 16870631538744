input {
    &[type="text"],
    &[type="number"] {
        border: none;
        width: 100%;

        &:focus {
            outline: none;
            border: none;
        }
    }

    &::-webkit-input-placeholder,
    &::placeholder {
        font-size: 1.6rem;
        font-style: italic;
        color: $color-grey-2;
        opacity: 1;
    }

}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.react-select {
    &-container {
    }

    &__single-value {
        color: $color-black !important;
        font-size: 1.6rem;
        font-weight: normal;
        font-style: normal;
    }
    &__control {
        position: relative !important;
        font-size: 1.6rem;
        font-style: italic;
        color: $color-grey-2;
        height: 3.8rem !important;

        @include form-border;
        @include form-focus;

        &--is-disabled {
            background-color: $color-grey-3 !important;
            border-color: $color-grey-2 !important;
        }
    }
    &__menu {
        margin: 0px 5px 5px 5px !important;
        padding: 6px 1px 1px 0px !important;
        border-radius: 4px !important;
        box-shadow: 2px 2px 13px 0 rgba(0, 0, 0, 0.5) !important;
        border: solid 1px #cccccc !important;
        background-color: #ffffff !important;
        position: absolute !important;
        width: 30rem !important;
        left: -1.7rem;
    }

    &__indicator {
        &-separator {
            background-color: transparent !important;
        }
        svg {
            width: 2rem;
            height: 2rem;
        }
    }
}


// Source - Nodal, NRG Stream - nodal drop down extra wide and on top
.react-select-container-nodal {
    .react-select__menu {
        width: 60.5rem !important;
        left: 1rem !important;
        top: 30.5rem !important;
        z-index: 5000 !important;
        position: fixed !important;
    }
    .react-select__option {
        padding: 0px 0 0.5rem 1rem !important;
    }
}

.react-select-container-nrg {
    .react-select__menu {
        width: 60.5rem !important;
        left: 1rem !important;
        top: 22rem !important;
        z-index: 5000 !important;
        position: fixed !important;
    }
    .react-select__option {
        padding: 0px 0 0.5rem 1rem !important;
    }
}

// 2nd drop down on row.   Make drop down align more
.react-select-container-2ndCol {
    .react-select__menu {
        left: -13.7rem !important
    }
}

.error{
    color:red;
}

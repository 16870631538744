// Custom.scss
// Option A: Include all of Bootstrap

// @import url('http://fonts.googleapis.com/css?family=Avenir');
@font-face {
    font-family: 'Avenir';
    src: url(./fonts/Avenir.woff) format('woff');
}

$font-main: 'Avenir', Helvetica, Arial, 'Lucida Grande', sans-serif;

$primary: #666666;
$font-family-base: 'Avenir';



// @import "node_modules/bootstrap/scss/bootstrap-grid";
// @import "node_modules/bootstrap/scss/bootstrap-reboot.scss";
// @import "node_modules/bootstrap/scss/_jumbotron";
// @import "node_modules/bootstrap/scss/_nav";

@import "node_modules/bootstrap/scss/bootstrap";

.h1 {
    font-family: $font-main;
}

.body {
    font-family: $font-main;
}
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%; // 1REM = 10px; 10px/16px = 62.50%
    scroll-behavior: smooth;

    @include respond(tab-land) {
        //font-size: 56.25%; //1REM = 9px, 9/16 = 56.25%
    }
    @include respond(tab-port) {
        //font-size: 50%; //1REM = 8px, 8/16 = 50%
    }
    @include respond(big-desktop) {
        //font-size: 75%;   //1REM = 12px, 12/16 = 75%
    }
}

body {
    box-sizing: border-box;
    background-color: $color-white;
    color: $color-black;
    overflow: hidden;
}

img {
    width: 100%;
    height: auto;
    display: block;
}


.container {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.6rem;

    @include respond(desktop) {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    height:100%;
    scrollbar-color: #eaeaea #888;
    scrollbar-width: thin;

    &-y{
        padding: 0 1.4rem 1.4rem 1.4rem;
    }
}

.tab-content{
    overflow: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: $color-grey-4;
}

.table th, .table td {
    padding: 0.5rem;
    vertical-align: top;
    border-top: 0;
    border-bottom: 1px solid #dee2e6;
    text-align: right;
}


.react-datepicker-wrapper {
    width: 100%;
}

::-webkit-scrollbar {
    width: 0.2em;
}
::-webkit-scrollbar-button {
    background: #eaeaea;
}
::-webkit-scrollbar-track-piece {
    background: #888;
}
::-webkit-scrollbar-thumb {
    background: #eaeaea;
}

.spinner-grow {
    top: -0.45rem !important;
}

.paddedNotBottom {
    padding: 5px 5px 0 5px;
}

.labelSmall {
    margin-bottom: 0.7rem;
    display: block;
    font-size: small;
}

.flex-container {
    display: flex;
  }

.flex-container-center {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
.margin-top-neg-small {
    margin-top: -0.5rem;
}
.margin-top-pos-xsmall {
    margin-top: 0.1rem;
}
.flex-gap-5 {
    gap: 5px;
}
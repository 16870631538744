@import "../../assets/sass/functions";

.aypaModal {
    // display: 'block';
    // width: 700;
    // padding: 30 
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;

    & :global(div.modal-dialog) {
        background-color: white;
        padding: 20px;
    }
}

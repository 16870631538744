@import "../../assets/sass/functions";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: $header-height;

    background-color: $color-black;
    color: $color-white;

    margin-bottom: $header-margin-bottom;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        height: calc(#{$header-height} + #{$header-margin-bottom});
        background-image: linear-gradient(
            90deg,
            (
                $color-gradient-1 0%,
                $color-gradient-2 20%,
                $color-gradient-3 40%,
                $color-gradient-4 60%,
                $color-gradient-5 80%,
                $color-gradient-6 100%
            )
        );
    }

    &__logo {
        display: flex;
        padding-left: 1.6rem;
        &__img {
            svg {
                width: 15.7rem;
                height: 2.7rem;
                fill: $color-white;
                padding-right: 1rem;
            }
        }

    }

    &__routes {
        display: flex;
        gap: 8px;
        span {
            border-left: 1px solid white;
            padding-left: 1rem;
            font-weight: bold;
            font-size: medium;
            display: inline-block;
            position: relative;
        }
        svg {
            height: 1.5rem;
            padding-right: 0.5rem;
            fill: $color-white;
        }
    }

    &__menu {
        position: relative;
        top: 0.3rem;

        span {
            font-weight: bold;
            font-size: medium;
            display: inline-block;
            position: relative;

            &:last-child {
                padding-right: 1.6rem;
            }
        }

        &__item {
            display: inline-block;
            &__iconbox {
                display: flex;
                align-items: center;
                gap: 8px;
                position: relative;
                svg {
                    height: 2rem;
                    fill: $color-white;
                }
                figure {
                    border: 1px solid black;
                    border-radius: 50%;
                    overflow: hidden;
                    display: inline-flex;
                    vertical-align: middle;
                    margin: 0 1rem 0 0;
                    position: relative;
    
                    img {
                        // height: 2.2rem;
                        height: 100%;
                        width: auto;
                    }
                }
            }

            @include respond(tab-port) {
                display: none;
            }
        }
    }

    a,
    a:hover,
    a:visited {
        text-decoration: none;
        color: $color-white;
    }
    &__navigation {
        &__fakebox {
            display: none;
        }
        &__button {
            top: 2rem;
            left: 1.6rem;
            display: none;
            position: relative;

            @include respond(tab-port) {
                display: block;
            }

            @include respond(phone) {
                //flex: 0 0 60%;
            }
        }
        &__checkbox:checked ~ .header__user-nav__navigation .header__user-nav {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            transition: transform 3.3s;
        }

        &__checkbox:checked ~ &__button {
        }
        &__checkbox:checked ~ &__fakebox {
            @include respond(tab-port) {
                display: flex;
            }
        }

        &__checkbox {
            display: none;
        }

        &__icon {
            position: relative;
            top: -0.8rem;
            //margin-top: 2rem;
            &,
            &::before,
            &::after {
                width: 3.6rem;
                height: 4.4px;
                background-color: $color-white;
                display: inline-block;
            }
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
            }

            &::before {
                top: -1rem;
            }

            &::after {
                top: 1rem;
            }
        }
    }
}

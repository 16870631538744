@import "common/assets/sass/functions";

.settings {
    display: flex;
    flex-direction: column;
    flex: 0 0 37.2rem;
    background-color: $color-white;
    border-right: solid 1px #eaeaea;

    @include respond(tab-port) {
        display: none;
        visibility: hidden;
        opacity: 0;
        transform: translateX(-31.2rem);
    }

    &__checkbox {
        display: none;
    }

    &__checkbox:checked ~ .settings {
        display: flex;
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        transition: transform .3s;
    }
}

.render {
    background-color: white;
    width: 100%;
    //border: 1px solid black;
    padding: 0;
}


$color-grey-3: #eceeef;
$color-grey-2: #dddddd;
$color-grey-4: #fafafa;
$color-grey-1: #b2b2b2;
$color-grey-5: #f7f7f7;

$color-orange-2: #e15d29;
$color-orange-3: #f4ae92;
$color-orange-1: #ad3304;

$color-gradient-1: #9e2629;
$color-gradient-2: #d1232a;
$color-gradient-3: #e15d29;
$color-gradient-4: #e8812c;
$color-gradient-5: #f3b229;
$color-gradient-6: #fee5a4;

$color-black: #000;
$color-white: #fff;


$default-font-size: 1.4rem;

$line: 1px solid #d8d8d8;



$header-height: 6rem;
$footer-height:3rem;
$header-margin-bottom: 0.4rem;
$settings-width: 28rem;
$home-padding: 1rem;


//RANGE


$track-color: #efefef;
$thumb-color: $color-orange-2;
$thumb-radius: 8px;
$thumb-height: 16px;
$thumb-width: 16px;
$thumb-shadow-size: 0;
$thumb-shadow-blur: 0;
$thumb-border-width: 0;

$track-height: 8px;
$track-shadow-size: 0;
$track-shadow-blur: 0;
$track-border-width: 1px;
$track-border-color: $color-grey-2;

$track-radius: 4px;

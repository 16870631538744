@import "common/assets/sass/functions";

.loading {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 30rem;
    &__spinner {
        margin-bottom: 2.4rem;

        -webkit-animation-name: spin;
        -webkit-animation-duration: 2000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: spin;
        -moz-animation-duration: 2000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -ms-animation-name: spin;
        -ms-animation-duration: 2000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;

        svg {
            width: 2.8rem;
            height: 2.8rem;
            fill: $color-orange-2;
        }
    }
    &__warning {
        margin-bottom: 0.8rem;
        svg {
            width: 4rem;
            height: 4rem;
            fill: red;
        }
    }
    &__text {
        text-align: center;
        color: $color-black;

        &grey {
            color: $color-grey-1;
        }
    }

    &__cta {
        margin-top: 1.7rem;
        width: 8.7rem;
        height: 3.8rem;
    }

    &__try {
        margin-top: 4.5rem;
    }
}

@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

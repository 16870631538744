@import "./../../assets/sass/functions";

.login{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background-color: $color-black;

    background-image: url(./../../assets/img/contact-hero-07-toronto-skyline.jpg);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__logo{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        width: 42.2rem;
        height: 14.6rem;
        margin: 0.25rem 0;

        border-radius: 12px;

        background-color: $color-white;
        background-image: 
        linear-gradient(270deg, ($color-white 0%, $color-white 97%, transparent 97%)),
        linear-gradient(
            180deg,
            (
                $color-gradient-1 0%,
                $color-gradient-2 20%,
                $color-gradient-3 40%,
                $color-gradient-4 60%,
                $color-gradient-5 80%,
                $color-gradient-6 100%
            )
        ), ;

        svg{
            width:28rem;
            height: 5rem;
            fill: $color-black;
        }
    }
    &__cta{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
         width: 42.2rem;
        height: 6.9rem;
        margin: 0.25rem 0;

        border-radius: 12px;
        background-color: $color-white;

        img{
            width: 21.5rem;
            height: 4.1rem;

            cursor: pointer;
        }
    }
    &__legal{
        position: absolute;

        top: calc(100vh - 2.7rem);

        font-size: 1.2rem;
        color: white;
    }
}

@import "common/assets/sass/functions";

.card {
    //border-top: 1px solid black;
    border-bottom: none;

    &__title {
        margin-right: auto;
        font-weight: normal;

        span {
            svg {
                width: 1.6rem;
                height: 1.6rem;
                margin-left: 0.7rem;
                position: relative;
            }
        }
    }
    &__cta {
        height: 3.8rem;
        margin-left: 1.6rem;

        span {
            top: -0.25rem;
            right: 1.1rem;
            position: relative;

            svg {
                width: 1.6rem;
                height: 1.6rem;
                fill: $color-white;
            }
        }

        a {
            padding-left: 2.2rem !important;
            bottom: -0.55rem;
            position: relative;
        }

        button {
            padding-left: 2.2rem;
        }
    }
    &__dropdown {
        height: 3.8rem;
        width: 13.8rem;
        margin-left: 1.6rem;

        span {
            top: -0.25rem;
            right: 1.1rem;
            position: relative;

            svg {
                width: 1.6rem;
                height: 1.6rem;
                fill: $color-white;
            }
        }
        button,
        button:hover {
            padding-left: 2.2rem;
            border: none;
            color: white;
            background-color: $color-orange-2;
            height: 100%;
            border-radius: 4px;
            width: 100%;
            padding: 0 1.1rem;
            font-size: unset;
        }
    }

    &__dropdownitem {
        height: 5.8rem;
        width: 28.5rem;
        padding: 5px;
        font-size: medium;
        margin-left: unset;

        span {
            top: -0.25rem;
            right: 1.1rem;
            position: relative;

            svg {
                width: 1.6rem;
                height: 1.6rem;
                fill: $color-white;
            }
        }

        // a {
        //     padding-left: 2.2rem !important;
        //     bottom: -0.55rem;
        //     position: relative;
        // }

        button,
        button:hover {
            padding-left: 2.2rem;
            border: none;
            color: white;
            background-color: $color-orange-2;
            height: 100%;
            border-radius: 4px;
            width: 100%;
            padding: 0 1.1rem;
            font-size: unset;
        }
    }
}

.header {
    display: flex;
    justify-content: space-between;

    background-color: white;
    border-bottom: none;
    height: 4.7rem;
    padding: 1.4rem 1.6rem;
    line-height: 1;
    z-index: 1;
    cursor: pointer;

    &:global(.active) {
        cursor: default;
    }
}

.body {
    height: calc(100vh - #{$header-height} - #{$footer-height} - #{$header-margin-bottom} - 15rem);
    flex: 0 0 auto;
    padding: 1.25rem 0 0 0;
}

.tabContent {
    padding: 1rem 1rem 2rem 1rem;
    flex: 1 1 auto;
    & :global(div.chartjs) {
        height: calc(100vh - #{$header-height} - #{$footer-height} - #{$header-margin-bottom} - 31rem);
        width: 100%;
        text-align: center;
        padding: 10px;
        min-height: 200px;
        max-height: 900px;
        button {
            height: unset;
            width: unset;
        }
    }
    & :global(div.tabContainer) {
        display: flex;
        justify-content: space-between;
        margin-top: -3rem;
        button {
            height: 3.8rem;
            width: 8rem;
        }
    }

    &.multiOneChart {
        height: 100%;
        & :global(div.chartjs)  {
            padding: 1rem 1rem 3rem 1rem;
        }
    }

    &.twoCharts {
        & :global(div.chartGroup) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        & :global(.loader) {
            height: calc(100vh - #{$header-height} - #{$footer-height} - #{$header-margin-bottom} - 25rem);
        }
    
        & :global(div.chartjs)  {
            flex: 0 0 calc((100% / 2) - 2rem);
            height: calc((100vh - #{$header-height} - #{$footer-height} - #{$header-margin-bottom} - 26.5rem) / 2);
            padding: 0 0 3rem 0;
    
            span {
                font-weight: normal;
                display: block;
            }
        }
    }
}


.tabTableContent {
    padding: 1rem 1rem;
    flex: 1 1 auto;
    & > div {
        width: 100%;
        padding: 1rem 2rem 1rem 1rem;
    }
}

.sectionTitleWithHelp {
    display: flex;
    width: fit-content;
    padding-left: 1.6rem;
}
.sectionTitleWithHelpCenter {
    display: flex;
    width: fit-content;
    padding-left: 1.6rem;
    flex-wrap: wrap;
    align-content: center;
}

.sectionTitle {
    margin-bottom: 1.4rem;
    padding-left: 1.6rem;

    svg {
        position: relative;
        bottom: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.6rem;
    }
}

.sectionTitleNoMargin {
    padding-left: 1.6rem;

    svg {
        position: relative;
        bottom: 0.2rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.6rem;
    }
}

.scheduleSearchSection {
    padding: 1rem;
    border-top: solid 1px #dddddd;
}


.tableContainer {
    padding: 1rem 2rem 1rem 1rem;
}

:global(.nav-tabs) {
    & :global(.nav-link) {
        padding: 0.8rem 1.4rem;
    }

    & :global( .nav-link.active) {
        border-bottom: none;
        color: black;
        background-color: #f0f0f0;
    }
    & :global(button) {
        color:#666;
    }
}

:global(.tab-content) {
    padding-top: 3rem;
    margin-top: 0.75rem;
}

:global(.down) {
    bottom: 0.2rem;
}

:global(.up) {
    bottom: -0.2rem;
}

@import "common/assets/sass/functions";

.cta {
    display: flex;
    width: 100%;
    margin-top: auto;
    justify-content: space-between;
    padding: 1.2rem 1.6rem;
    background-color: $color-grey-5;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    
    &__button {
        flex: 0 0 calc((100% - 0.6rem) / 2);
        height: 3.8rem;
    }
}

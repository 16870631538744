@import "common/assets/sass/functions";

.releaseNotesContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    overflow-y: auto;
  }
  
  h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .releaseNote {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
  }
  
  .releaseNoteHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .version {
    font-weight: bold;
    font-size: 1.25rem;
    color: #007bff;
  }
  
  .date {
    font-size: 1rem;
    color: #888;
  }
  
  .changeList {
    margin: 10px 0;
    padding-left: 20px;
  
    li {
      margin-bottom: 8px;
    }
  }
  
  .changeType {
    font-weight: bold;
    color: #28a745;
  }

//juste pour que le heigh d'un float left soit rempli et non de valeur 0
@mixin clearfix {
    &::after{
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


@mixin form-focus {
    &:focus,
    &:focus-within{
        box-shadow: none;
        border-color: none;
        border: 1px solid $color-orange-2 !important;
    }
    &:disabled{
        background-color: $color-grey-3 !important;
    }
}

@mixin  form-border {
    border: 1px solid $color-grey-1 !important;
    border-radius: 5px !important;
    background-color: white !important;
} 

// MEDIA QUERIES MANAGER

/*
0px - 600px:        phone
600px - 900px:      tablet portrait
900px - 1200px:     tablet landscape
1200px - 1800px:    desktop
1800px + :          big desktop


*/


/*
$breakpoint argunment choices:
- phone
- tab-port
- tab-land
- big-desktop


1REM = 16 PX


ORDER: Base + Typography > general layout + grid > page layout + components
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content }; // 600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 81.25em) { @content }; //1300px
    }
    @if $breakpoint == desktop {
        @media only screen and (max-width: 82.5em) { @content }; //1320px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content }; //1800PX
    }
}